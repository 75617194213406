import React from "react";

import { Stage, Layer, Line, Circle, Text } from "react-konva";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";

const DynamicVAChart = ({
  dynamicVAStatus,
  isShowingValues
}) => {

  const setYAxisLines = (yValues, yLabels, isLeft) => {
    // return the lines, but Each child in a list should have a unique "key" prop.
    const lines = [];

    // define new aux variable for lines
    let paddingLeftLine = 100;
    let paddingLeftText = 50;
    let alignText = "right";
    if (!isLeft) {
      paddingLeftLine = 510;
      paddingLeftText = 515;
      alignText = "left";
    }

    yValues.forEach((yValue, index) => {
      lines.push(
        <Line
          points={[paddingLeftLine, yValue, paddingLeftLine - 10, yValue]}
          stroke="black"
          strokeWidth={1}
        />
      );
      lines.push(
        <Line
          points={[100, yValue, 650, yValue]}
          stroke="gray"
          strokeWidth={0.4}
        />
      );
      lines.push(
        <Text
          x={paddingLeftText}
          y={yValue - 8}
          width={35}
          text={yLabels[index]}
          fontSize={16}
          fontFamily="system-ui"
          align={alignText}
        />
      );
    });


    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  const setXAxisLines = (xValue, xLabels) => {
    // return the lines, but Each child in a list should have a unique "key" prop.
    const lines = [];

    // define new aux variable for lines
    let paddingLeftLine = 510;
    let paddingLeftText = 520;
    let alignText = "center";

    xValue.forEach((xValue, index) => {
      lines.push(
        <Line
          points={[xValue, paddingLeftLine - 10, xValue, paddingLeftLine]}
          stroke="black"
          strokeWidth={1}
        />
      );
      lines.push(
        <Text
          x={xValue - 15}
          y={paddingLeftText}
          width={30}
          text={xLabels[index]}
          fontSize={16}
          fontFamily="system-ui"
          align={alignText}
        />
      );
    });

    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  const setDots = () => {
    // read min and max values to set the dots
    const min = dynamicVAStatus?.velocity_kmh_min;
    const max = dynamicVAStatus?.velocity_kmh_max;

    if (min === undefined || max === undefined) {
      return null;
    }

    const dots = [];
    // set the initial values
    const initialPointIndex = min / 5;

    // check acuity_list and actual_step
    const acuityList = dynamicVAStatus?.acuity_list;
    const actualStep = dynamicVAStatus?.actual_step;

    if (acuityList === undefined) {
      console.error("acuity_list is undefined");
    }
    else {
      // set the lines first
      let color = "#5DD55D";
      acuityList.forEach((yValue, index) => {
        dots.push(
          <Line
            points={[90 + (initialPointIndex + index - 1) * 60, 45 * acuityList[index - 1] + 40, 90 + (initialPointIndex + index) * 60, 45 * yValue + 40]}
            stroke={color}
            strokeWidth={3}
          />
        );
      });

      // set the dots 
      acuityList.forEach((yValue, index) => {
        dots.push(
          <Circle
            x={90 + (initialPointIndex + index) * 60}
            y={45 * yValue + 40}
            radius={8}
            fill={color}
          />
        );
      });
    }

    // set the actual step dot
    const actualAcuity = dynamicVAStatus?.actual_acuity;
    const isConfirmation = dynamicVAStatus?.is_confirmation;
    if (actualAcuity === undefined || isConfirmation === undefined) {
      console.error("actual_acuity or is_confirmation is undefined");
    }
    else {
      // check if is the last step, if so, do not set the dot
      const isLastStep = ((max / 5) - initialPointIndex + 1) === actualStep;
      if (!isLastStep) {
        const color = isConfirmation ? "#FFA500" : "#FF0000";
        dots.push(
          <Circle
            x={90 + (initialPointIndex + actualStep) * 60}
            y={45 * actualAcuity + 40}
            radius={8}
            fill={color}
          />
        );
      }
    }

    return dots.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  const setValues = () => {
    // read min and max values to set the dots
    const min = dynamicVAStatus?.velocity_kmh_min;
    const max = dynamicVAStatus?.velocity_kmh_max;

    if (min === undefined || max === undefined) {
      return null;
    }

    const dots = [];
    // set the initial values
    const initialPointIndex = min / 5;

    // check acuity_list and actual_step
    const acuityList = dynamicVAStatus?.acuity_list;
    const timeList = dynamicVAStatus?.time_list;

    if (acuityList === undefined || timeList === undefined) {
      console.log("acuity_list or time_list is undefined");
    }
    else {
      // set the values 
      timeList.forEach((yValue, index) => {
        dots.push(
          <Text
            x={97 + (initialPointIndex + index) * 60}
            y={45 * acuityList[index] + 54}
            text={(1000*yValue).toFixed(0) + " ms"}
            fontSize={15}
            fill="black"
            fontFamily="system-ui"
            fontStyle="bold"
            rotation={90}
          />
        );
      });
    }
    return dots.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      p={1}
      textAlign={"-webkit-center"}
    >
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item lg={12} xl={12} >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width={750}
              height={601}
            >
              <Box
                width={701}
                height={601}
              >
                <Stage width={701} height={601}>
                  <Layer>
                    <Line
                      points={[100, 0, 100, 500]}
                      stroke="black"
                      strokeWidth={1}
                    />
                    <Line
                      points={[100, 500, 650, 500]}
                      stroke="black"
                      strokeWidth={1}
                    />
                    <Text
                      x={10}
                      y={450}
                      width={400}
                      align="center"
                      rotation={270}
                      text="Agudeza visual (decimal)"
                      fontSize={25}
                      fontFamily="system-ui"
                    />
                    {setYAxisLines(
                      [490, 445, 400, 355, 310, 265, 220, 175, 130, 85, 40],
                      ["0.0", "0.1", "0.2", "0.3", "0.4", "0.5", "0.6", "0.7", "0.8", "0.9", "1.0"],
                      true
                    )}
                    <Text
                      x={175}
                      y={560}
                      width={400}
                      align="center"
                      text="Velocidad (km/h)"
                      fontSize={25}
                      fontFamily="system-ui"
                    />
                    {setXAxisLines(
                      [150, 210, 270, 330, 390, 450, 510, 570],
                      ["5", "10", "15", "20", "25", "30", "35", "40"]
                    )}

                    {setDots()}
                    {isShowingValues ? setValues() : null}

                  </Layer>
                </Stage>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};
export default DynamicVAChart;