import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, DialogContentText, DialogActions } from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';

import { ref, onValue, remove, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { AuthContext } from "../../providers/AuthContext";
import { useTranslation } from 'react-i18next';

import ContrastSensitivityPDF from "../PDFdocuments/ContrastSensitivityPDF";

function descendingComparator(a, b, orderBy) {
  // Si el valor no es una cadena, ordena como antes
  if (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy], 'es', { sensitivity: 'base' });
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'dateId',
    numeric: false,
    disablePadding: true,
    label: 'Fecha de realización',
  },
  {
    id: 'targetEye',
    numeric: false,
    disablePadding: false,
    label: 'Ojo',
  },
  {
    id: 'cpd',
    numeric: false,
    disablePadding: false,
    label: 'Ciclos por grado',
  }
];

// region Table Head
function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {numSelected > 0 ?
            <Checkbox
              color="primary"
              disabled={numSelected == 0}
              checked={false}
              indeterminate={numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
            : null
          }
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <strong>{headCell.label}</strong>
          </TableCell>
        ))}
        <TableCell padding="checkbox"></TableCell>
      </TableRow>
    </TableHead>
  );
}
// endregion Table Head

// region Table Toolbar
function EnhancedTableToolbar(props) {
  const { numSelected, handleDeleteSelected, handleDownloadSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Sensibilidad al contraste realizados
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
          <Tooltip title="Descargar selección">
            <IconButton>
              <DownloadIcon onClick={handleDownloadSelected} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar selección">
            <IconButton>
              <DeleteIcon onClick={handleDeleteSelected} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  handleDownloadSelected: PropTypes.func.isRequired,
};
// endregion Table Toolbar

// region Main Table
export default function ConstrastSensitivityHistoryTable() {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dateId');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const { uid } = useParams();
  const { t } = useTranslation();

  // region Table loading
  useEffect(() => {

    setIsLoading(true);

    // in this case, data is loaded from main database, no historical resume data
    const dataRef = ref(database, `contrast_sensitivity/${currentUser.uid}/${uid}`);

    onValue(dataRef, (snapshot) => {
      const historicalData = snapshot.val();
      if (!historicalData) {
        setRows([]);
        setIsLoading(false);
        return;
      }
      const mappedData = Object.keys(historicalData).map(dateId =>
        createData(dateId, historicalData[dateId])
      );
      setRows(mappedData);
      setIsLoading(false);
    });
  }, [currentUser]);

  function createData(dateId, data) {
    const cpd = data.cpd;
    let targetEye = -1;
    if (data.target_eye != null) {
      targetEye = data.target_eye;
    }
    if (data.is_left != null) {
      targetEye = data.is_left ? 0 : 1;
    }

    return {
      dateId,
      cpd,
      targetEye
    };
  }
  // endregion

  // region Table checkbox
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    // si no está se añade
    if (selectedIndex === -1) {
      // antes de añadirlo hay que comprobar si se puede, ya que si hay algo seleccionado, solo puede haber dos secciones, una de cada ojo, y en principio del mismo tipo
      const checkCorrect = checkMultipleSelection(id);
      if (checkCorrect) {
        newSelected = newSelected.concat(selected, id);
      }
      else {
        newSelected = newSelected.concat(selected);
      }
    }
    // si está se elimina dependiendo de su posicion 
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const checkMultipleSelection = (newId) => {
    const newRow = rows.find(row => row.dateId === newId);
    // check all selected rows
    for (let i = 0; i < selected.length; i++) {
      const selectedRow = rows.find(row => row.dateId === selected[i]);
      // if the eye is different, return false
      if (selectedRow.targetEye !== newRow.targetEye) {
        handleSnackbarOpen("Solo se pueden combinar datos de ojos iguales");
        return false;
      }
      // if the section is the same, return false
      if (selectedRow.cpd === newRow.cpd) {
        handleSnackbarOpen("No se pueden combinar pruebas con el mismo cpd");
        return false;
      }
    }

    return true;
  };
  const handleSelectAllClick = (event) => {
    setSelected([]);
  };
  // endregion

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const formatDate = (originalDate) => {
    if (originalDate === "-") {
      return "-";
    }

    // date is in format YYYYMMDD-HHMMSS and we want to show DD-MM-YYYY (hh:mm)
    const date = originalDate.split("-")[0];
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const time = originalDate.split("-")[1];
    const hour = time.substring(0, 2);
    const minutes = time.substring(2, 4);
    return `${day}-${month}-${year} (${hour}:${minutes})`;
  }

  const formatEye = (eye) => {
    if (eye === 0) {
      return "Izquierdo";
    }
    if (eye === 1) {
      return "Derecho";
    }
    return "Ambos";
  }

  // region Delete logic
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const descriptionDeleteDialogRef = useRef(null);
  React.useEffect(() => {
    if (openDeleteDialog) {
      const { current: descriptionDeleteDialog } = descriptionDeleteDialogRef;
      if (descriptionDeleteDialog !== null) {
        descriptionDeleteDialog.focus();
      }
    }
  }, [openDeleteDialog]);

  const startDeletingSelected = () => {
    // vamos a eliminar las campimetrías seleccionadas de firebase
    if (selected.length !== 0) {
      for (let i = 0; i < selected.length; i++) {
        const dateId = selected[i];
        if (dateId != null && dateId !== "-" && dateId !== "") {
          const dataRef = ref(database, `contrast_sensitivity/${currentUser.uid}/${uid}/${dateId}`);
          try {
            remove(dataRef);
          } catch (error) {
            console.error("Error removing document: ", error);
          }
        }
      }
      setSelected([]);
      setOpenDeleteDialog(false);
    }
  };
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [contrastData, setContrastData] = useState({
    cpd_1_5_step: 0,
    cpd_3_step: 0,
    cpd_6_step: 0,
    cpd_12_step: 0,
    actual_user: ""
  });
  const [contrastTestDate, setContrastTestDate] = useState("");
  const startGeneratingPDF = (actualDateSel) => {
    fetchContrastData([actualDateSel]);
    setGeneratingPDF(true);
  }
  const startGeneratingCombinedPDF = () => {
    if (selected.length !== 0) {
      fetchContrastData(selected);
      setGeneratingPDF(true);
    }
  }
  const stopGeneratingPDF = () => setGeneratingPDF(false);

  // recorremos las fechas seleccionadas y obtenemos los datos de cada una
  const fetchContrastData = async (dates) => {
    const contrastData = {
      cpd_1_5_step: 0,
      cpd_3_step: 0,
      cpd_6_step: 0,
      cpd_12_step: 0,
      actual_user: "",
      target_eye: -1
    };

    for (let i = 0; i < dates.length; i++) {
      const dateId = dates[i];
      const dataRef = ref(database, `contrast_sensitivity/${currentUser.uid}/${uid}/${dateId}`);
      try {
        const snapshot = await get(dataRef);
        if (snapshot.exists()) {
          const data = snapshot.val();

          if (contrastData.actual_user === "") {
            contrastData.actual_user = data.user_id;
          }
          else if (contrastData.actual_user !== data.user_id) {
            console.error("Different users in the same test");
          }

          if (contrastData.target_eye === -1) {
            if (data.target_eye != null) {
              contrastData.target_eye = data.target_eye;
            }
            else if (data.is_left != null) {
              contrastData.target_eye = data.is_left ? 0 : 1;
            }
          }
          else {
            if (data.target_eye != null) {
              if (contrastData.target_eye !== data.target_eye) {
                console.error("Different target_eye types in the same test");
              }
            }
            else if (data.is_left != null) {
              if (contrastData.target_eye !== (data.is_left ? 0 : 1)) {
                console.error("Different target_eye types in the same test");
              }
            }
          }

          if (data.cpd === 1.5) {
            contrastData.cpd_1_5_step = data.final_step;
          }
          else if (data.cpd === 3) {
            contrastData.cpd_3_step = data.final_step;
          }
          else if (data.cpd === 6) {
            contrastData.cpd_6_step = data.final_step;
          }
          else if (data.cpd === 12) {
            contrastData.cpd_12_step = data.final_step;
          }
          else {
            console.error("Unknown cpd value");
          }
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    }
    setContrastData(contrastData);

    const auxTestDate = dates.reduce((fechaMasReciente, fechaActual) => {
      return fechaActual > fechaMasReciente ? fechaActual : fechaMasReciente;
    });
    setContrastTestDate(auxTestDate);
  };
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region HTML
  return (
    <React.Fragment >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ width: '95%', maxWidth: 1000, mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} handleDeleteSelected={handleOpenDeleteDialog} handleDownloadSelected={startGeneratingCombinedPDF} />
          <TableContainer>
            <Table
              sx={{ minWidth: 350 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.dateId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      //hover
                      //onClick={(event) => handleClickRow(event, row.id)}
                      //role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.dateId}
                      selected={isItemSelected}
                    //sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Evita que el evento de clic se propague a la TableRow
                            handleClick(event, row.dateId); // Llama a la función específica del Checkbox
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        width="28%"
                      >
                        {formatDate(row.dateId)}
                      </TableCell>
                      <TableCell width="34%">{formatEye(row.targetEye)}</TableCell>
                      <TableCell width="24%">{row.cpd}</TableCell>
                      <TableCell width="6%">
                        <IconButton color="primary">
                          <CloudDownloadIcon onClick={() => startGeneratingPDF(row.dateId)} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      {/* Snackbar for warning messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          Eliminar pruebas seleccionadas
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionDeleteDialogRef}
            tabIndex={-1}
          >
            ¿Estás seguro que quieres eliminar las pruebas seleccionadas?
            <br />
            <em>Esta acción no se puede deshacer</em>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {t('close')}
          </Button>
          <Button color="error" variant="contained" onClick={startDeletingSelected}>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <ContrastSensitivityPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        contrastData={contrastData}
        testDate={contrastTestDate}
        contrastEye={contrastData.target_eye}
      >
      </ContrastSensitivityPDF>

    </React.Fragment>
  );
}
